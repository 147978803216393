body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.side-left-1 {
  margin-top: 140px !important;
  background: #FFFFFF;
  border-radius: 8px;
  min-height: 267px;
  
}
.footer-stripe{
    background: #000;
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    align-items: center;
    text-align: center;
}
.player-wrapper {
  position: relative;
  
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.footer-stripe  div.player-wrapper{
  fill: rgb(255, 255, 255);
  z-index: 1;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 14px
}
.duration{
  color:#FFFFFF;
   
   
  margin-left: 23px;
}
div.player-wrapper .group svg{
  fill: #fff;

}
.slider .MuiSlider-root{
  color:#FFFFFF;
  width: 350px;
}

.rc-slider,
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  width: 100%;
  background-color: #e9e9e9;
}
.rc-slider-rail,
.rc-slider-track {
  position: absolute;
  height: 4px;
  border-radius: 6px;
}
.rc-slider-track {
  left: 0;
  background-color: #abe2fb;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -5px;
  cursor: grab;
  border-radius: 50%;
  border: 2px solid #96dbfa;
  background-color: #fff;
  touch-action: pan-x;
}
.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #57c5f7;
  box-shadow: 0 0 0 5px #96dbfa;
}
.rc-slider-handle:focus {
  outline: none;
}
.rc-slider-handle-click-focused:focus {
  border-color: #96dbfa;
  box-shadow: unset;
}
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {
  border-color: #ffffff;
  box-shadow: 0 0 5px #ffffff;
  cursor: -webkit-grabbing;
  cursor: grabbing;
  widows: 350px
}
.rc-slider-mark {
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  font-size: 12px;
}
.rc-slider-mark-text {
  position: absolute;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.rc-slider-dot {
  position: absolute;
  bottom: -2px;
  margin-left: -4px;
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  vertical-align: middle;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-dot-reverse {
  margin-right: -4px;
}
.rc-slider-disabled {
  background-color: #e9e9e9;
}
.rc-slider-disabled .rc-slider-track {
  background-color: #ccc;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-handle {
  border-color: #ccc;
  box-shadow: none;
  background-color: #fff;
  cursor: not-allowed;
}
.rc-slider-disabled .rc-slider-dot,
.rc-slider-disabled .rc-slider-mark-text {
  cursor: not-allowed !important;
}
.rc-slider-vertical {
  width: 14px;
  height: 100%;
  padding: 0 5px;
}
.rc-slider-vertical .rc-slider-rail {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-track {
  left: 5px;
  bottom: 0;
  width: 4px;
}
.rc-slider-vertical .rc-slider-handle {
  margin-left: -5px;
  touch-action: pan-y;
}
.rc-slider-vertical .rc-slider-mark {
  top: 0;
  left: 18px;
  height: 100%;
}
.rc-slider-vertical .rc-slider-step {
  height: 100%;
  width: 4px;
}
.rc-slider-vertical .rc-slider-dot {
  left: 2px;
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:first-child {
  margin-bottom: -4px;
}
.rc-slider-vertical .rc-slider-dot:last-child {
  margin-bottom: -4px;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: block !important;
  animation-play-state: paused;
}
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active,
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active {
  animation-name: rcSliderTooltipZoomDownIn;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: rcSliderTooltipZoomDownOut;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-appear,
.rc-slider-tooltip-zoom-down-enter {
  transform: scale(0);
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.rc-slider-tooltip-zoom-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }
  to {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(0);
  }
}
.rc-slider-tooltip {
  position: absolute;
  left: -9999px;
  top: -9999px;
  visibility: visible;
}
.rc-slider-tooltip,
.rc-slider-tooltip * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-tooltip-hidden {
  display: none;
}
.rc-slider-tooltip-placement-top {
  padding: 4px 0 8px;
}
.rc-slider-tooltip-inner {
  padding: 6px 2px;
  min-width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  text-align: center;
  text-decoration: none;
  background-color: #6c6c6c;
  border-radius: 6px;
  box-shadow: 0 0 4px #d9d9d9;
}
.rc-slider-tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}
.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  bottom: 4px;
  left: 50%;
  margin-left: -4px;
  border-width: 4px 4px 0;
  border-top-color: #6c6c6c;
}
.rc-slider {
  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 30%;
  border-radius: 6px;
  touch-action: none;
}
.slider{
  justify-content: center;
    align-items: center;
    display: flex;
    text-align: right;
}
@media only screen and (max-width: 600px) {
  iframe {
    width: 100%;
    height: 220px;
  }
  .side-left-1{
    display: none;
  }
  .slide-right-1{
    width: 100% !important;
    justify-content: center;
    display: grid;
  }
  img {
    /* position: fixed; */
    top: 30px;
    left: 26%;
  }
  h4 {
    font-size: 2rem !important;
    margin-bottom: 20px;
  }
  /* .steev-main {
    align-items: unset !important;
  } */
  .steev-hero {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
  }
}

.group1{
  top: 0;
  /* position: absolute; */
}
.player video{
  display: none;
}
.mt-slider{
  /* margin-top: 68px; */
}