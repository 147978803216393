@font-face {
  font-family: Helvetica-Bold;
  src: url(../src/assets/Helvetica-Font/Helvetica-Bold.ttf);
}
@font-face {
  font-family: Helvetica;
  src: url(../src/assets/Helvetica-Font/Helvetica.ttf);
}
@font-face {
  font-family: Helvetica-Light;
  src: url(../src/assets/Helvetica-Font/helvetica-light-587ebe5a59211.ttf);
}
.tag-d .MuiChip-label{
  font-weight: 400;
  line-height: 22px;
  font-family: 'Helvetica';
  font-size: 12px;
}

.tag-d-0 .MuiChip-label{
  color: rgba(0, 122, 255, 0.8);
}

.tag-d-1 .MuiChip-label{
  color: #FDBD2F;
}

.tag-d-2 .MuiChip-label{
  color: #28CD41;
}

@media (min-width: 1280px){
.MuiContainer-maxWidthLg {
    /* max-width: 1108px!important; */
}}

.backgroundcolortitle{
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
border-radius: 16px;
}

span{
  font-weight: 600;
}